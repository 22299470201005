.logo {
    height: 32px;
    margin: 16px;
    text-align: center;
    font-weight: bold;
    color: #fafafa;
    font-size: 16pt;
    cursor: pointer;
}

.MuiListItemIcon-root {
    min-width: 30px !important;
    margin-right: 2px !important;
}

.margin {
	margin-left: 25%;
}

.menu-icon {
    max-width: 20px !important;
}

._title {
    margin: 2% auto 2% 0;
}

body {
    font-size: 12pt !important;
    font-family: Arial, sans-serif;
    font-weight: normal;
}

.MuiFormLabel-root.Mui-disabled {
    color: rgba(84, 84, 84, 0.5) !important;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #888;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #03346c;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.MuiDrawer-paperAnchorDockedLeft {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #089fc7 !important;
}

.MuiDrawer-paperAnchorLeft {
    left: 0;
    right: auto;
    background-color: #089fc7 !important;
}

.MuiDrawer-paperAnchorLeft {
    flex-shrink: 0;
    padding-left: 5px;
    padding-right: 5px;
    width: 240px;
}

.MuiTableCell-root {
    padding: 10px !important;
}

.MuiAppBar-colorPrimary {
    color: #000000 !important;
    box-shadow: none !important;
}

.header {
    justify-content: flex-start;
    min-height: 60px !important;
    max-height: 60px !important;
}

.MuiListItem-root {
    margin-bottom: 4px!important;
    margin-left: 4px !important;
}

.logo{
    width: 160px;
    margin-top: 20px;
    margin-left: 15px;
}
