html * {
  font-family: "Montserrat", sans-serif !important;
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly !important;
  margin-bottom: 2%;
  margin-top: 2%;
}

.my-container {
  padding: 2% 10% 2% 10%;
  margin: 4% 15% 2% 15%;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 1%;
}

@media only screen and (max-width: 575px) {
  .my-container-responsive {
    margin: 4% 2% 2% 2% !important;
  }
}

.form-container-sub {
  padding-top: 2%;
  padding-left: 3%;
  padding-bottom: 2%;
  margin: 4% 15% 2% 15%;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 1%;
  display: flex;
  flex: 1;
  justify-content: center;
}

@media only screen and (max-width: 575px) {
  .my-form-container-sub-responsive {
    padding-left: 1% !important;
    padding-right: 1% !important;
    margin: 4% 0% 2% 0% !important;
    width: 100% !important;
  }
}

.btn-container {
  margin-top: 6%;
  display: flex;
  justify-content: space-evenly;
}

.summary-description {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly !important;
  margin-bottom: 2%;
  background-color: white !important;
  padding: 1%;
}

.form-installer {
  display: flex;
  justify-content: space-around;
  margin-top: 2%;
}

.select-item {
  width: 40%;
}

.divider {
  font-weight: 800 !important;
}

.btn-confirm-container {
  display: flex;
  justify-content: center;
}

.btn-confirm {
  margin-top: 2%;
  margin-bottom: 4%;
  width: 15%;
  min-width: 190px;
}

.profileDescriptionContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.profileDescription {
  margin-top: 10vh;
  margin-bottom: 3%;
  width: 70%;
  background-color: white !important;
  padding: 1%;
}

@media only screen and (max-width: 575px) {
  .profileDescriptionResponsive {
    width: 95% !important;
  }
}

.card-opt {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.my-option-container {
  margin-top: 5%;
  display: flex;
  justify-content: center;
}

.exportForm {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

[class*="jss"] {
  z-index: 1 !important;
}

.tableTitle {
  margin-top: 3.6vh;
  margin-bottom: 5vh !important;
  display: flex;
  justify-content: center;
  color: #089fc7 !important;
  font-size: large;
}

.test {
  display: flex;
  justify-content: center;
  color: #089fc7 !important;
  font-size: large;
  font-weight: bold;
}

@media only screen and (max-width: 575px) {
  .titleContainerResponsive {
    width: 90% !important;
    display: flex;
    justify-content: space-between;
  }
}

.titleContainer {
  width: 58%;
  display: flex;
  justify-content: space-between;
}

.titleBack {
  display: flex;
  justify-content: center;
  color: #089fc7 !important;
  font-size: large;
}

.back-button {
  margin-top: 4vh;
}

.subscriptionInfo {
  display: flex;
  justify-content: space-evenly;
}

.recharts-wrapper {
  width: 100%;
}

.content {
  padding: 2% 10% 2% 10%;
  width: 100%;
  background-color: white;
}

.contentResponsive {
  padding: 2% 4% 2% 4%;
  width: 100%;
  background-color: white;
}

[class^="MUIDataTableHeadCell-"] {
  font-weight: bold !important;
}

.headerContainer {
  height: 68px !important;
  position: fixed;
  width: 90%;
  z-index: 10;
}

.headerResponsive {
  height: 68px !important;
  display: flex !important;
  justify-content: space-between !important;
  position: fixed;
  width: 90%;
  z-index: 10;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

td.ant-table-column-sort {
  background: inherit !important;
}

.cell {
  display: flex;
  justify-content: space-between;
}

.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
}

@media only screen and (max-width: 575px) {
  .ant-menu-dark.ant-menu-inline,
  .ant-menu-dark.ant-menu-vertical,
  .ant-menu-dark.ant-menu-vertical-left,
  .ant-menu-dark.ant-menu-vertical-right {
    border-right: 0;
  }
}

html {
  overflow: scroll;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: transparent; /* make scrollbar transparent */
}

.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 64px;
  background: #ffce35 !important;
}

.ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
  position: fixed;
  width: 200px;
  min-width: 200px;
  background: #089fc7 !important;
  max-width: 200px;
}

@media only screen and (max-width: 575px) {
  .ant-layout-sider-children {
    height: 100%;
    margin-top: -0.1px;
    padding-top: 0.1px;
    position: fixed;
    width: 200px;
    min-width: 200px;
    background-color: transparent !important;
    max-width: 200px;
  }
}

@media only screen and (max-height: 414px) {
  .titleContainerHeigth {
    width: 75% !important;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .ant-layout-sider-children {
    margin-top: 10% !important;
    height: 100%;
    padding-top: 1px;
    position: fixed;
    width: 200px;
    min-width: 200px;
    background-color: transparent !important;
    max-width: 200px;
  }
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: rgb(0 0 0) !important;
  font-size: 11pt;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #089fc7 !important;
}

.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  opacity: 1;
  color: black !important;
  font-size: 11pt !important;
}

.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 34px;
  color: black !important;
}

.MuiListItem-gutters {
  padding-left: 2px !important;
  padding-right: 16px;
}

.MuiAppBar-colorPrimary {
  z-index: 1000 !important;
}

iframe {
  display: none;
}
