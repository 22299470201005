.timeline-chart-container{
    margin-right: 5vw;
    margin-left: 10vw;
}

.tabStyle {
    margin-right: 5vw;
    margin-left: 5vw;
    margin-top: 2vh;
    margin-bottom: 2vh;
    max-height: 100%;
    height: 100%;
}

.chartStyle {
    margin-top: 3%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.chartStyleResponsive {
    margin-bottom: 3%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
}

.checkboxStyle {
    margin-left: 1.0em;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    padding-left: 1%;
}

.ant-checkbox + span {
    padding-right: 0px !important;
    padding-left: 8px;
}

.checkboxStyleResponsive {
    display: flex;
    justify-content: center;
}

.options {
    margin-top: 2%;
    margin-bottom: 2%;
    display: flex;
    justify-content: space-between;
}

.legend {
    margin-top: 25%;
    margin-bottom: 2%;
}

.cards {
    margin-top: 2vh;
    margin-bottom: 10vh;
    display: flex;
    justify-content: space-around;
}

.carduser {
    margin-top: 8%;
    display: flex;
    justify-content: space-around;
}

.dataDiv {
    margin-bottom: 10vh;
}

.my-select-container {
    display: flex;
    justify-content: space-around;
}

.my-select {
    width: 30%;
}

.maintenance-container {
    display: flex;
    justify-content: center;
    padding-bottom: 5%;
}

@media only screen and (max-width: 769px) {
    .card-responsive {
        margin-bottom: 4%;
    }
}

.wires-table{
    margin-bottom: 2%;
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
	border:0;
	width: 100%;
}