.dark-row{
  background-color: #fafafa;
}

.light-row {

}
.verticalBorder th {
  border: 1px solid rgba(224, 224, 224, 1);
}

[class^="MUIDataTableHeadCell-fixedHeader-"] {
  top: 0;
  z-index: 0 !important;
  position: inherit !important;
  background-color: #fff;
}

[class^="MUIDataTable-"] {
  padding: 2% !important;
}

[class*=jss] {
  padding-left: 2% !important;
  padding-right: 2% !important;
}

.MuiTableCell-head {
  font-weight: 500;
  line-height: 1.5rem;
  background-color: #119fc780 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}